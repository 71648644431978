.home-page {
  min-height: 100vh;
  background-color: #f0f2f5;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}

.countdown-row {
  margin-top: 20px;
}

.countdown-card {
  text-align: center;
  margin: 10px auto;
  width: 80%;
}

.countdown-title {
  font-size: 24px;
  color: #3f8600;
}

.countdown-value {
  font-size: 36px;
}

.countdown-value.months {
  color: #1f78d1; /* Ajusta estos colores según tus necesidades */
}

.countdown-value.weeks {
  color: #6c757d;
}

.countdown-value.days {
  color: #dc3545;
}

.election-events {
  width: 80%;
  margin-top: 20px;
}

.event-item {
  margin: 10px 0;
}

.event-item.past {
  color: #dc3545;
}

.event-item.current {
  color: #ffc107;
}

.event-item.future {
  color: #28a745;
}
